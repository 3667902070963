@import '@styles/styles.scss';

.wrapper {
    &.sellPaying,
    &.buyPaying {
        .amountInfosWrapper {
            margin-top: 0;
        }
        .infosGroupWrapper {
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;
        }

        @include tablet {
            .infosGroupWrapper {
                flex-direction: column-reverse;
            }
        }
    }

    &.sellPayingTwoStepSimple,
    &.buyPayingManual {
        .amountInfosWrapper {
            width: 100%;
            margin-top: $SPACING_LAYOUT_MEDIUM;
            margin-left: 0;

            .itemFieldList {
                flex-direction: column;
            }
        }
    }
}
