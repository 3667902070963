@import '@styles/styles.scss';

button.btn.btnResend {
    border: none;
    border-radius: 0;
    background-color: $COLOR_SILVER;

    &:hover {
        background-color: $COLOR_MIDGREY;
    }
    .countDownSecondTxt,
    .text {
        color: $COLOR_BLACK;
    }
}
