@import '@styles/styles.scss';

.itemField {
    // @include desktop {
    //     .itemFieldLeft {
    //         .fieldLabelWrapper {
    //             min-width: auto !important;
    //         }
    //     }
    // }
    &.sellerName .itemTags {
        @include mobileLarge {
            margin-top: 8px;
        }
    }
}
