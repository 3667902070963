@import '@styles/styles.scss';

.stepper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    width: 800px;
    margin-left: auto;
    margin-right: auto;

    .step {
        &.currStep,
        &.completedStep {
            .stepItm {
                opacity: 1;

                .stepIndex {
                    color: $COLOR_WHITE;
                    background-color: $primaryColor;
                }
            }
        }

        &.completedStep {
            .stepHorizontalLine {
                background-color: $primaryColor;
            }
        }

        .stepItm {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;
            min-width: 180px;
            font-size: $FONTSIZE_18;
            color: $primaryColor;
            background-color: $COLOR_WHITE;
            color: $COLOR_BLACK;

            .tickIco {
                width: 30px;
                height: 30px;
            }

            .stepConnector {
                z-index: 1;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 28px;
            }

            .stepIndex {
                font-size: $FONTSIZE_30;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 60px;
                height: 60px;
                border-radius: 50%;
                box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.18);
                background-color: $COLOR_WHITE;
            }

            .stepName {
                text-align: center;
            }
        }

        .stepHorizontalLine {
            z-index: -1;
            position: absolute;
            left: calc(50%);
            right: calc(-50%);
            height: 8px;
            background-color: #cccccc;
        }
    }
    .stepper .step .stepHorizontalLine {
        width: 280px;
    }
    &.stepper3steps {
        .stepHorizontalLine {
            width: 280px;

            @include tablet {
                width: 220px;
            }

            @include mobileLarge {
                width: 175px;
            }

            @include mobile {
                width: 100px;
            }
        }
    }

    &.stepper2steps {
        .stepHorizontalLine {
            width: 620px;

            @include tablet {
                width: 480px;
            }

            @include mobileLarge {
                width: 315px;
            }

            @include mobile {
                width: 180px;
            }
        }
    }

    @include tablet {
        width: 680px;
    }

    @include mobileLarge {
        width: 450px;
        .step {
            .stepItm {
                min-width: 125px;
            }

            .stepName {
                font-size: $FONTSIZE_16;
            }
        }
    }

    @include mobile {
        width: 300px;
        margin-top: 20px;
        margin-bottom: 20px;
        .step {
            .stepItm {
                min-width: 90px;

                .stepIndex {
                    font-size: $FONTSIZE_16;
                    width: 28px;
                    height: 28px;
                }

                .tickIco {
                    width: 16px;
                    height: 16px;
                }

                .stepName {
                    font-size: $FONTSIZE_14;
                }

                .stepConnector {
                    margin-bottom: 8px;
                }
            }

            .stepHorizontalLine {
                height: 2px;
            }
        }
    }
}
