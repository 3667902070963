@import '@styles/styles.scss';

.recipientInfos {
    .infosWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .leftInfos {
        width: 100%;
    }
    .payMethodOptions {
        display: flex;

        .ItemPayMethodOption + .ItemPayMethodOption {
            margin-left: 5px;
        }
    }

    .itemFieldList {
        &.accountInfos {
            display: flex;
            flex-wrap: wrap;
            .itemField {
                width: 50%;
            }
        }
    }

    ol {
        font-size: $FONTSIZE_BODY;
        line-height: 20px;
        margin-left: 15px;
    }
    @include tablet {
        .itemFieldList {
            &.accountInfos {
                flex-direction: column;
                .itemField {
                    width: auto;
                }
            }
        }
    }
}
