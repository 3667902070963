@import '@styles/styles.scss';

.btnCopyWrapper {
    .txtCopy {
        color: $COLOR_BLACK;
    }

    .iconCopy {
        display: none;
    }

    .btn.btnCopy {
        background-color: $COLOR_SILVER;
        margin: 0;
        &:hover {
            background-color: $COLOR_MIDGREY;
        }
        &.MuiButton-text {
            color: $COLOR_BLACK;
            height: 26px;
            min-height: auto;
            width: 56px;
            min-width: 56px;

            @include mobile {
            }
        }
    }
}
