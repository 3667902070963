@import '@styles/styles.scss';

.resultedWrapper {
    .orderInfos {
        margin-top: 38px;

        &.amountInfosWrapper {
            width: 100%;

            .leftInfos {
                .itemFieldList {
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }

    @include mobileLarge {
        .orderInfos {
            margin-left: 0;
        }
    }
}
