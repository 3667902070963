@import '@styles/styles.scss';

/*=============================================
=                 Page Templates              =
=============================================*/
.wrapper.buyPaying,
.wrapper.sellPaying {
    padding-top: 0;
}

.mainInfosWrapper {
    min-width: 800px;
    background-color: $COLOR_WHITE;

    @include tablet {
        min-width: auto;
    }
}
/*=====  End of Page Templates  ======*/

/*=============================================
=                 Buttons                     =
=============================================*/
.btnGroupWrapper {
    display: flex;
    justify-content: center;
}

.btn {
    &.MuiButton-root,
    &.MuiButton-contained,
    &.MuiButton-outlined {
        border-radius: 0;
        box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.11);
        &:hover {
            box-shadow: none;
        }
    }

    &.MuiButton-contained {
        svg .fillArea {
            fill: $primaryTextContrast;
            stroke: $primaryTextContrast;
        }

        &.Mui-disabled {
            background-color: #ef6373;
            opacity: 0.5;
            .MuiButton-label {
                color: $primaryTextContrast;
            }
        }
    }

    &.MuiButton-outlined {
        border: 1px solid #ef6373;
        box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.11);
        background-color: rgba(176, 176, 176, 0.04);

        &:hover {
            box-shadow: none;
            color: #b0b0b0;
        }

        .MuiButton-label {
            color: $primaryColor;
        }

        svg .fillArea {
            fill: $primaryColor;
        }
    }
}

.dialogActionGroup {
    &.MuiDialogActions-root {
        display: flex;
        justify-content: center;
    }
}

/*=====  End of Buttons  ======*/
.itemField.unitCount,
.itemField.realAmount {
    .unit,
    .numeric {
        color: $primaryColor;
        line-height: 1;
    }
}

.qrBoardInfosWrapper {
    border: none;
    box-shadow: none;
}

.snackbarWrapper {
    &.MuiAlert-standardSuccess {
        background-color: $infosBkColor;
        border: 1px solid $COLOR_MIDGREY;
    }
}
