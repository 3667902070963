@import '@styles/styles.scss';

.amountInfosWrapper {
    width: 100%;

    .infosWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .mainInfosItemsWrapper {
        width: 100%;
    }

    .leftInfos {
        .itemFieldList {
            display: flex;
            justify-content: space-between;
        }
    }

    .rightInfos {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-top: 20px;
    }

    @include tablet {
        width: auto;
        margin-left: 0;
        margin-top: 20px;
    }

    @include mobile {
        .leftInfos {
            .itemFieldList {
                display: block;
            }
        }

        .rightInfos {
            .realAmount {
                .itemFieldLeft {
                    align-items: flex-start;
                }
            }
        }
    }
}
