@import '@styles/styles.scss';

.orderInfos {
    background-color: $infosBkColor;
    margin-top: 20px;
    border: 1px solid #e5e5e5;
    box-shadow: 0 2px 10px 4px rgba(37, 39, 38, 0.05);

    .infosTitle {
        font-size: $FONTSIZE_24;
        font-weight: bold;
        color: $primaryTextContrast;
        background-color: $primaryColor;
        padding: 15px 25px;

        @include mobile {
            padding: 10px 15px;
            font-size: $FONTSIZE_18;
        }
    }

    .infosWrapper {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 25px 25px 20px 25px;

        @include mobile {
            padding: $SPACING_LAYOUT_MEDIUM;
        }

        .rightInfos {
            &.fieldLabelWrapper {
                min-width: 20px;
            }
        }

        .buyUnit,
        .sellUnit {
            .fieldLabel {
                font-size: $FONTSIZE_18;
            }

            .field {
                font-weight: bold;
                font-size: $FONTSIZE_30;
                color: $COLOR_RED;
            }
        }
    }
}
